/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Main, DropdownArrow, ActivityDropdown, FieldTitle, CustomMenuItem, StyledInputNew } from '../../AdminTeamCreation/styles';
import { ChallengeHeading, Container, InputContainer, StyledInputV2, StyledLabel, UploadPhotoContainer, RemoveImage, InputImage, CustomTextArea, ButtonContainer, /*NextButton,*/ Button, CustomRadioButton } from '../challengeCreate/styles';
import StepsHeading from './stepsName';
import { getOrientation, resetOrientation, checkImage } from '../../../utils/methods';
import { toast } from 'react-toastify';
import { imageErrorMessage } from '../../../utils/constants';
import { connect } from 'react-redux';
import { saveChallengeInformation, getChallengeInformation, getAllCompanies } from '../../../redux/actions';
import { behaviorChallengePointValues } from '../../../../mockData';
import { Heading, Locationwrapper, TitleContainerV2, FieldTitleV2, DownArrow, StyledDateTime, ContainerWrapper, TitleContainerNew } from './styles'
import moment from 'moment';
import Datetime from 'react-datetime';

class LaunchChallenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 1,
      step: 1,
      imgSrc: null,
      description: '',
      title: '',
      imageName: '',
      videoTitle: '',
      introductionVideo: '',
      stepValue: 1,
      arrow: false,
      selectPoints: '',
      radio: null,
      location: [],
      department: [],
      challengeLaunchDate: null,
      startDate: null,
      openDatePicker: false,
      startDateSchedule: null,
      count: 0,
      showDateTimeInput: '',
      openDateTimePicker: '',
    }
  }

  componentDidMount() {
    const { getChallengeInformation, challengeData, fetchAllCompanies } = this.props;
    fetchAllCompanies();
    getChallengeInformation();
    this.setState({
      title: challengeData.title,
      videoTitle: challengeData.videoTitle,
      introductionVideo: challengeData.introductionVideo,
      description: challengeData.description,
      imgSrc: challengeData.imgSrc
    });
  }

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeImg = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      const array = document.getElementById('behavior-challenge-file').value.split("\\");
      reader.onloadend = () => {
        this.setState({
          imgSrc: reader.result,
          imageName: array[array.length - 1],
        });
        getOrientation(file, (or) => {
          resetOrientation([reader.result], or, (baseImage) => {
            this.setState({
              imgSrc: baseImage
            });
          });
        });
      };
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('behavior-challenge-file').value = '';
    }
  };

  removePhoto = () => {
    this.setState({
      imgSrc: null,
      imageName: '',
    });
  };

  manageSteps = (value) => {
    this.setState({
      stepValue: value
    });
  };

  onSelectPoints = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  getRadioButtonStatus = (value, name) => {
    if (name === "invite") {
      this.setState({
        radio: value
      });
    } else {
      this.setState({
        challengeLaunchDate: value,
        startDate: moment().format('MMM DD, YYYY')
      });
    }
  }

  onChangeLocation = (id) => {
    const { location } = this.state;
    if (location.includes(id)) {
      let index = location.findIndex((item) => item === id);
      if (index > -1) {
        location.splice(index, 1);
      }
    } else {
      location.push(id);
    }
    this.setState({
      location: location
    })
  }

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { location } = this.state;
    if (location.length == locationDetails.length) {
      this.setState({
        location: []
      })
    }
    else {
      let arr = [];
      for (let index = 0; index < locationDetails.length; index++) {
        arr.push(locationDetails[index].id);
      }
      this.setState({
        location: arr
      })
    }
  }

  onChangeDepartment = (id) => {
    const { department } = this.state;
    if (department.includes(id)) {
      let index = department.findIndex((item) => item === id);
      if (index > -1) {
        department.splice(index, 1);
      }
    } else {
      department.push(id);
    }
    this.setState({
      department: department
    })
  }

  selectAllDepartment = (e, departmentDetails) => {
    e.preventDefault();
    const { department } = this.state;
    if (department.length == departmentDetails.length) {
      this.setState({
        department: []
      })
    }
    else {
      let arr = [];
      for (let index = 0; index < departmentDetails.length; index++) {
        arr.push(departmentDetails[index].id);
      }
      this.setState({
        department: arr
      })
    }
  }

  changeDate = (date) => {
    this.setState({
      startDateSchedule: date,
      openDatePicker: false
    });
  };

  onDateBlur = () => {
    this.setState({ openDatePicker: false });
  };

  showDatePicker = () => {
    this.setState((prev) => ({
      openDatePicker: !prev.openDatePicker
    }))
  };

  closeDate = (e) => {
    if (e.target.className === 'form-control') {
      if (this.state.count > 0 && e.target.className === 'form-control' && this.state.openDatePicker) {
        this.setState({ openDatePicker: false })
      }
      else {
        this.setState({ count: 1 })
      }
    }
  }

  launchChallengeStepFirst = () => {
    const { description, title, selectPoints, imgSrc } = this.state;
    return (
      <ContainerWrapper>
        <Layout marginBottom="0px">
          <Main>
            <ChallengeHeading>
              Create a Wellness Challenge
            </ChallengeHeading>
            <Container>
              <div className="stepsNameHighlight">
                <StepsHeading stepCount={1} />
              </div>
              <div className="form">
                <div className="heading">
                  <div className="step">1</div>
                  <div className="headingName">Introduce about the challenge to your employees</div>
                </div>
                <div className="formBody">
                  <InputContainer>
                    <StyledLabel>Title Of the Challenge{<span>*</span>}</StyledLabel>
                    <StyledInputV2
                      type={"text"}
                      placeholder={"Enter Name of the Challenge"}
                      name={"title"}
                      onChange={this.onChangeInput}
                      value={title}
                      maxLength={100}
                    />
                  </InputContainer>

                  <StyledLabel>Challenge Image{<span>*</span>}</StyledLabel>
                  <UploadPhotoContainer>
                    <div className='innerDivImageContainer'>
                      <img alt='profilre-img' src={this.state.imgSrc ? this.state.imgSrc : "/images/default.png"} ></img>
                      {this.state.imgSrc == null ? <InputImage className="edit-profile-pic " imageReload={1}>
                        <i className="addImg">{"Upload Image"}</i>
                        <input
                          id="behavior-challenge-file"
                          type="file"
                          name="user-profile"
                          multiple={false}
                          accept=".jpeg, .png, .jpg"
                          onChange={(e) => this.onChangeImg(e)}
                          onClick={(e) => e.target.files[0] && this.onChangeImg(e)}
                        />
                      </InputImage> :
                        <RemoveImage onClick={this.removePhoto}>{"Remove Image"}</RemoveImage>}
                    </div>
                  </UploadPhotoContainer>
                  <InputContainer>
                    <StyledLabel>About The Challenge{<span>*</span>}</StyledLabel>
                    <CustomTextArea
                      placeholder="Write Descirption about the Challenge"
                      rows="8"
                      name="description"
                      value={description}
                      onChange={this.onChangeInput}
                      data-gramm_editor="false"
                      type="text"
                    />
                  </InputContainer>
                  <TitleContainerNew fullWidth>
                    <FieldTitle>{"Wellness Challenge Level"}</FieldTitle>
                    <DropdownArrow alt='image' drop={this.state.arrow} src={this.state.arrow ? "/public/images/teamDownArrow.svg" : "/public/images/dropdown_arrow.svg"} />
                    <ActivityDropdown
                      placeholder="Select Option"
                      title={selectPoints ? selectPoints : "Select Wellness Challenge Level"}
                      id="dropdown-recurring"
                      onClick={() => this.setState({ arrow: !this.state.arrow })}
                    >
                      {
                        behaviorChallengePointValues.map((item, index) => (
                          <CustomMenuItem
                            eventKey={index}
                            key={index}
                            onSelect={() => this.onSelectPoints('selectPoints', item['value'])}
                            active={selectPoints == item['value']}
                          >
                            {item['value']}
                          </CustomMenuItem>
                        ))
                      }
                    </ActivityDropdown>
                  </TitleContainerNew>
                </div>
              </div>
            </Container>
          </Main>
        </Layout>
        <ButtonContainer>
          <div className="wrapper">
            <Button onClick={() => this.manageSteps(2)} marginAuto="1" disabled={!description || !title || !imgSrc}>
              {"Next >>"}
            </Button>
          </div>
        </ButtonContainer>
      </ContainerWrapper>
    )
  }

  launchChallengeStepSecond = () => {
    const { radio, location, department } = this.state;
    const { allCompanies, departmentDetails } = this.props;
    return (
      <ContainerWrapper>
        <Layout marginBottom="0px">
          <Main>
            <ChallengeHeading>
              Create a Wellness Challenge
            </ChallengeHeading>
            <Container>
              <div className="stepsNameHighlight">
                <StepsHeading stepCount={2} />
              </div>
              <div className="form">
                <div className="heading">
                  <div className="step">2</div>
                  <div className="headingName">Let’s set the audience</div>
                </div>
                <div className="formBody">
                  <StyledLabel>Would You Like to Invite?</StyledLabel>
                  <div className='radioButtonDiv'>
                    <span><CustomRadioButton onClick={() => this.getRadioButtonStatus(radio == "location" ? null : "location", "invite")} > {radio == "location" && <div></div>} </CustomRadioButton> Locations</span>
                    {/* <span><CustomRadioButton onClick={() => this.getRadioButtonStatus(radio == "department" ? null : "department", "invite")}> {radio == "department" && <div></div>} </CustomRadioButton> Departments</span> */}
                  </div>
                  {radio == "location" ?
                    <Heading>
                      <div className="name">Company Location To Invite</div>
                      <div className='selectAllRadio'>
                        <span><CustomRadioButton onClick={(e) => this.selectAllLocation(e, allCompanies)} > {allCompanies && location.length === allCompanies.length && <div></div>} </CustomRadioButton > {allCompanies && location.length === allCompanies.length ? 'Deselect All' : 'Select All'}</span>
                      </div>
                    </Heading> :
                    radio == "department" ?
                      <Heading>
                        <div className="name">Company Department To Invite</div>
                        <div className='selectAllRadio'>
                          <span><CustomRadioButton onClick={(e) => this.selectAllDepartment(e, departmentDetails)} > {departmentDetails && department.length === departmentDetails.length && <div></div>} </CustomRadioButton > {departmentDetails && department.length === departmentDetails.length ? 'Deselect All' : 'Select All'}</span>
                        </div>
                      </Heading> : null}
                  {radio == "location" ?
                    <Locationwrapper>
                      {allCompanies && allCompanies.length > 0 ? allCompanies.map((loc, index) => (
                        <div className='checkBoxWidth' key={index}>
                          <span><CustomRadioButton onClick={() => this.onChangeLocation(loc.id)} > {location.includes(loc.id) && <div></div>} </CustomRadioButton>{loc.company_name}</span>
                        </div>)) : null}
                    </Locationwrapper> :
                    radio == "department" ?
                      <Locationwrapper>
                        {departmentDetails && departmentDetails.length > 0 ? departmentDetails.map((dept, index) => (
                          <div className='checkBoxWidth' key={index}>
                            <span><CustomRadioButton onClick={() => this.onChangeDepartment(dept.id)} > {department.includes(dept.id) && <div></div>} </CustomRadioButton>{dept.department}</span>
                          </div>)) : null}
                      </Locationwrapper> : null}
                </div>
              </div>
            </Container>
          </Main>
        </Layout>
        <ButtonContainer>
          <div className="wrapper">
            <Button color={'white'} textColor={'#9c9c9c'} border={'#9c9c9c'} width={"113px"} marginLeft="1" onClick={() => this.manageSteps(1)}>
              {"Go Back"}
            </Button>
            <Button disabled={location.length === 0} onClick={() => this.manageSteps(3)} marginAuto="1">
              {"Next >>"}
            </Button>
          </div>
        </ButtonContainer>
      </ContainerWrapper>
    )
  }

  launchChallengeStepThird = () => {
    const { challengeLaunchDate, startDate, openDatePicker, startDateSchedule, showDateTimeInput } = this.state;
    const yesterday = Datetime.moment();
    // const previousLimit = Datetime.moment().subtract(100, 'years');
    const valid = (currentDate) => showDateTimeInput === 'date' ? currentDate.isAfter(Datetime.moment()) : currentDate.isAfter(yesterday);
    return (
      <ContainerWrapper>
        <Layout marginBottom="0px">
          <Main>
            <ChallengeHeading>
              Create a Wellness Challenge
            </ChallengeHeading>
            <Container>
              <div className="stepsNameHighlight">
                <StepsHeading stepCount={3} />
              </div>
              <div className="form">
                <div className="heading">
                  <div className="step">3</div>
                  <div className="headingName">Set the date and Launch Challenge</div>
                </div>
                <div className="formBody">
                  <StyledLabel>Please select your start date for this 21 day challenge</StyledLabel>
                  <div className='radioButtonDiv'>
                    <span><CustomRadioButton onClick={() => this.getRadioButtonStatus(challengeLaunchDate == "now" ? null : "now", "date")} > {challengeLaunchDate == "now" && <div></div>} </CustomRadioButton>Launch a Challenge Now</span>
                    <span><CustomRadioButton onClick={() => this.getRadioButtonStatus(challengeLaunchDate == "schedule" ? null : "schedule", "date")}> {challengeLaunchDate == "schedule" && <div></div>} </CustomRadioButton>Schedule Challenge for a Feature Date</span>
                  </div>
                  {challengeLaunchDate == "now" && <div className="dateCard">
                    {
                      <TitleContainerV2 width={"47.5%"}>
                        <FieldTitleV2 >{"Challenge Start Date"}</FieldTitleV2>
                        <StyledInputNew
                          placeholder="Challenge Start Date"
                          name="title"
                          value={startDate}
                          type="text"
                          disabled

                        />
                      </TitleContainerV2>}
                    <div className="separate">-</div>
                    {
                      <TitleContainerV2 width={"47.5%"}>
                        <FieldTitleV2 >{"Challenge End Date"}<span className="note">(Auto)</span></FieldTitleV2>
                        <StyledInputNew
                          placeholder="Challenge Start Date"
                          name="title"
                          value={startDate? moment(startDate).add(21, 'days').format('MMM DD, YYYY'):"End Date"}
                          type="text"
                          disabled
                        />
                      </TitleContainerV2>}
                  </div>}
                  {challengeLaunchDate == "schedule" &&
                    <div className="dateCard">
                      <InputContainer width={"47.5%"} onClick={(e) => this.closeDate(e)} style={{ position: 'relative' }} margin="1" padding="15px 0px 10px 0px" >
                        <FieldTitleV2 >{"Challenge Start Date"}</FieldTitleV2>

                        <StyledDateTime
                          inputProps={{ placeholder: "Select DOB", readOnly: true }}
                          open={openDatePicker}
                          dateFormat="MMM DD, YYYY"
                          closeOnSelect={true}
                          closeOnTab={true}
                          timeFormat={false}
                          onChange={this.changeDate}
                          value={startDateSchedule}
                          isValidDate={valid}
                          viewDate={startDateSchedule ? startDateSchedule : yesterday}
                          onBlur={this.onDateBlur}
                          onFocus={this.showDatePicker}
                          viewMode="years"
                        />
                        <DownArrow opened={openDatePicker}
                          onClick={() => this.showDatePicker()}
                        >
                          <img alt="calender" src="/public/images/calendar.svg"></img>
                        </DownArrow>
                      </InputContainer>
                      <div className="separate">-</div>
                      {
                        <TitleContainerV2 width={"47.5%"}>
                          <FieldTitleV2 >{"Challenge End Date"}<span className="note">(Auto)</span></FieldTitleV2>
                          <StyledInputNew
                            placeholder="End Date"
                            name="title"
                            value={startDateSchedule? moment(startDateSchedule).add(21, 'days').format('MMM DD, YYYY'):"End Date"}
                            type="text"
                            disabled
                          />
                        </TitleContainerV2>}

                    </div>}
                </div>
              </div>
            </Container>
          </Main>
        </Layout>
        <ButtonContainer>
          <div className="wrapper">
            <Button color={'white'} textColor={'#9c9c9c'} border={'#9c9c9c'} width={"113px"} marginLeft="1" onClick={() => this.manageSteps(2)}>
              {"Go Back"}
            </Button>
            <Button color="linear-gradient(90deg,rgb(0,111,188) -1.11%,rgb(127,67,255) 97.46%)" onClick={() => this.props.history.push('/company/challenges/categories')} marginAuto="1" disabled={!startDate || !challengeLaunchDate}>
              {"Launch a Challenge"}
            </Button>
          </div>
        </ButtonContainer>
      </ContainerWrapper>
    )
  }

  render() {
    const { stepValue } = this.state;
    return (
      stepValue === 1 ? this.launchChallengeStepFirst() : stepValue === 2 ? this.launchChallengeStepSecond() : this.launchChallengeStepThird()
    );
  }
}

LaunchChallenge.propTypes = {
  history: PropTypes.object,
  challengeData: PropTypes.object,
  saveChallengeInformation: PropTypes.func,
  getChallengeInformation: PropTypes.func,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  allCompanies: PropTypes.array,
  fetchAllCompanies: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  challengeData: state.challengesAdmin.challengeData,
  locationDetails: state.register.locationDetails,
  departmentDetails: state.register.departmentDetails,
  allCompanies: state.challenges.allCompanies
});

const mapDispatchToProps = (dispatch) => ({
  saveChallengeInformation: (name, value) => dispatch(saveChallengeInformation(name, value)),
  getChallengeInformation: () => dispatch(getChallengeInformation()),
  fetchAllCompanies: () => dispatch(getAllCompanies())
});

export default connect(mapStateToProps, mapDispatchToProps)(LaunchChallenge);
